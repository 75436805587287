<template>
  <div>

    <v-alert type="error" v-if="error">{{error}}</v-alert>
      <v-card class="mb-3" v-if="!firstRun">
        <v-card-text>

          <v-text-field
        type="password"
        label="AD Password"
        v-model="password"
        autofocus
        @keypress.enter="test('passwordExpired')"
      ></v-text-field>
      <v-alert type="info" v-if="!password.length">Please enter your AD password to start</v-alert>
      <v-alert type="info" v-if="loading">Loading data...</v-alert>
        </v-card-text>
      </v-card>
     
    <div class="mb-3" v-if="password">
      <v-btn color="primary" @click="test('passwordExpired')" small class="mr-2 mb-2"
        >Expired Passwords</v-btn
      >
      <v-btn color="primary" @click="test('inactiveAccounts')" small class="mr-2 mb-2"
        >Inactive Accounts</v-btn
      >

      <v-btn color="primary" @click="test('lockedOut')" small class="mr-2 mb-2"
        >Locked Out</v-btn
      >
      <v-btn color="primary" @click="test('disabled')" small class="mr-2 mb-2"
        >Disabled Accounts</v-btn
      >
      <v-btn color="primary" @click="test('failedPassword')" small class="mr-2 mb-2"
        >Failed Passwords</v-btn
      >


      
      <v-btn color="primary" @click="test('passwordReset')" small class="mr-2 mb-2"
        >Password Reset Needed</v-btn
      >
      <v-btn @click="test('passwordWarning')" small class="mr-2 mb-2"
        >Password Expiring within 7 days</v-btn
      >
      <v-btn @click="test('noExpire')" small class="mr-2 mb-2"
        >Passwords w/ No Expiry</v-btn
      >
      <v-btn @click="test('all')" small class="mr-2 mb-2">All Users</v-btn>
    </div>
    <h3>{{resultTitle}}</h3>
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
    ></v-text-field>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :items-per-page="25"
      :loading="loading"
    ></v-data-table>
  </div>
</template>

<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { mapState } from "vuex";
dayjs.extend(utc);
dayjs.extend(timezone);

const person = "(objectClass=person)";
const user = `(&(!(objectClass=computer))${person})`;
// const group = "(objectClass=group)";
// const everyoneCn = `ou=VIP,dc=vipdeskconnect,dc=com`;
// const everyone = `(memberOf:1.2.840.113556.1.4.1941:=${everyoneCn})`;
const disabled = `(userAccountControl:1.2.840.113556.1.4.803:=2)`;
const enabled = `(!${disabled})`;
const lockedOut = `(lockoutTime>=1)`;
const failedPassword = `(badPwdCount>=1)`;
const noExpiration = `(userAccountControl:1.2.840.113556.1.4.803:=65536)`;
const expirable = `(userAccountControl:1.2.840.113556.1.4.803:<>65536)`;
const resetNeeded = `(pwdLastSet=0)`;
// const groupFilter = []
//     .concat(params && params.memberOf ? params.memberOf : [])
//     .map(group => `(memberof=${group})`)
//     .join('')
let expiredDate = convertToAdDate(dayjs().subtract(90, "days").format("x"));
let expiredWarning = convertToAdDate(dayjs().subtract(83, "days").format("x"));
console.log(expiredDate);
const searches = {
  all: `(&${user})`,
  // memberOf: `(&${enabled}(&(objectClass=user)(|${groupFilter})))`,
  //   group: `(&${group})`,
  lockedOut: `(&${user}${lockedOut})`,
  failedPassword: `(&${user}${failedPassword})`,
  disabled: `(&${user}${disabled})`,
  passwordExpired2: `(&${user}(!${noExpiration})${enabled}(pwdLastSet<=${expiredDate}))`,
  passwordExpired: `(&${user}(pwdLastSet<=${expiredDate})${enabled}(!${noExpiration}))`,
  inactiveAccounts: `(&${user}(lastLogonTimestamp<=${expiredDate})${enabled})`,
  noExpire: `(&${user}${noExpiration}${enabled})`,
  passwordReset: `(&${user}${enabled}${resetNeeded}(!${noExpiration}))`,
  passwordWarning: `(&(pwdLastSet<=${expiredWarning})${user}${enabled}(!${resetNeeded})${user}${enabled}(!${noExpiration})(pwdLastSet>=${expiredDate}))`,
  passwordWarning2: `(&(pwdLastSet<=${expiredWarning})(pwdLastSet>=${expiredDate})${user}${enabled}(!${resetNeeded})(!${noExpiration})${expirable})`,
  // email: params.value.split(/[;,]/)
  //     .reduce((result, item) => (`${result}(mail=${item})(userPrincipalName=${item})(proxyAddresses=smtp:${item})(otherMailbox=${item})`), '(|') + ')',
  // phone: params.value.split(/[;,]/)
  //     .reduce((result, item) => (`${result}(telephone=${item})(homePhone=${item})(mobile=${item})(otherMobile=${item})(otherHomePhone=${item})`), '(|') + ')',
  // username: params.value.split(/[;,]/)
  //     .reduce((result, item) => (`${result}(sAMAccountName=${item})`), '(|') + ')',
  // dn: params.value.split(/[;]/)
  //     .reduce((result, item) => (`${result}(distinguishedName=${item})`), '(|') + ')'
};

function convertFromAdDate(date, days) {
  var epoch = parseInt((date / 10000000 - 11644473600) * 1000);
  if (days) {
    epoch = epoch + days * 24 * 60 * 60 * 1000;
  }
  return epoch > 0 ? dayjs(epoch).format() : null;
}

function convertToAdDate(epoch, days) {
  var date = parseInt((epoch / 1000 + 11644473600) * 10000000);
  if (days) {
    date = date + days * 24 * 60 * 60 * 10000000;
  }
  console.log(date);
  return date;
}

export default {
  data() {
    return {
      search: "",
      firstRun: false,
      results: [],
      error: null,
      loading: false,
      password: "",
      resultTitle: "",
      headers: [
        { text: "Status", value: "userAccountControl" },
        { text: "Name", value: "displayName" },
        { text: "Username", value: "sAMAccountName" },
        { text: "OU", value: "dn" },
        { text: "department", value: "department" },
        { text: "company", value: "company" },
        { text: "Title", value: "title" },
        { text: "Password Set", value: "pwdLastSet" },
        { text: "Bad Password", value: "badPasswordTime" },
        { text: "Last Logon (<>14 day)", value: "lastLogonTimestamp" },
        { text: "Created", value: "whenCreated" },
        { text: "Changed", value: "whenChanged" },
        { text: "Personal Mailbox", value: "otherMailbox" },
      ],
    };
  },
  computed: {
    ...mapState(["user"]),
    username() {
      return (this.user.email || "").split("@")[0];
    },
    items() {
      let headers = this.headers.map((item) => item.value);
      if (!this.results || !this.results.length){
        console.log("no results");
        return []
      }
      return this.results.map((item) => {
        return headers.reduce((obj, key) => {
          console.log(key, item);
          obj[key] = item[key] || "Unknown";
          switch (key) {
            case "whenCreated":
            case "whenChanged":
              obj[key] = dayjs
                .tz(obj[key].split(".")[0], "YYYYMMDDHHmmss", "utc")
                .tz("EST5EDT")
                .fromNow();
              break;
            case "dn":
              obj[key] = obj[key].split("=")[2].split(",")[0];
              break;
            case "userAccountControl":
              obj[key] = obj[key] === "514" || obj[key] === "66050" ? "Disabled" : `Active`;
              break;
              case "lastLogonTimestamp":
              obj[key] = convertFromAdDate(obj[key])
                ? -dayjs(convertFromAdDate(obj[key])).diff(dayjs(), "day") +
                  " Days Ago" +
                  " (" +
                  dayjs(convertFromAdDate(obj[key])).format("MMM D, YYYY") +
                  ")"
                : "Never";
              break;
              case "badPasswordTime":
            case "pwdLastSet":
              // console.log(convertFromAdDate(obj[key]));
              obj[key] = convertFromAdDate(obj[key])
                ? -dayjs(convertFromAdDate(obj[key])).diff(dayjs(), "day") +
                  " Days Ago" +
                  " (" +
                  dayjs(convertFromAdDate(obj[key])).format("MMM D, YYYY") +
                  ")"
                : "Never";
              break;
          }
          return obj;
        }, {});
      });
    },
  },
  methods: {
    async test(key) {
      this.loading = true;
      this.error = null

      switch(key){
        case "lockedOut":
          this.resultTitle = "Locked Out Users"
          break;
        case "failedPassword":
          this.resultTitle = "Failed Passwords"
          break;
        case "disabled":
          this.resultTitle = "Disabled Accounts"
          break;
          case "all":
          this.resultTitle = "All Users"
          break;
          case "passwordExpired":
          this.resultTitle = "Expired Passwords"
          break;
          case "inactiveAccounts":
          this.resultTitle = "Inactive Accounts"
          break;
          case "noExpire":
          this.resultTitle = "Passwords that dont Expire"
          break;
          case "passwordReset":
          this.resultTitle = "Password Reset Needed"
          break;
          case "passwordWarning":
          this.resultTitle = "Passwords soon to expire"
          break;
      }
      console.log({
        username: this.username,
        password: this.password,
      });
      let resp = await fetch(
        "https://51k6diido9.execute-api.us-east-1.amazonaws.com/prod/public-ldap-proxy",
        {
          method: "POST",
          body: JSON.stringify({
            account: {
              username: this.username,
              password: this.password,
            },
            key: "search",
            params: {
              filter: searches[key],
              scope: "sub",
              sizeLimit: 1000,
              paged: true,

              attributes: [],
            },
          }),
        }
      ).catch(err=>{
        this.error = err
        this.loading = false;
      })
      let payload = await resp.json();
      console.log(payload);
      if (payload.error){
        if (payload.error.lde_message == '80090308: LdapErr: DSID-0C090439, comment: AcceptSecurityContext error, data 52e, v4563\u0000'){
          this.error = "Invalid Credentials. Check your password and try again."
          this.loading = false;
          return
        }
        this.error = payload.error
        this.loading = false;
        return
      }
      this.firstRun=true
      this.results = payload;
      this.loading = false;
    },
  },
};
</script>

<style>
</style>